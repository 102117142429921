
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { CultureList } from '@/types/school'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  components: { UploadFile }
})

export default class Add extends Vue {
  private info: CultureList = {
    projectId: '',
    name: '',
    date: [],
    content: '',
    startDate: '',
    endDate: '',
    fileList: []
  }

  private rules = {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: 'change' }
    ],
    name: [
      { required: true, message: '请输入文化科普名称', trigger: 'change' }
    ],
    date: [
      { required: true, message: '请选择展示周期', trigger: 'change' }
    ],
    content: [
      { required: true, message: '请输入文化科普信息', trigger: 'change' }
    ],
    fileList: [
      { required: true, message: '请选择文化科普图片', trigger: 'change' }
    ]
  }

  private submitShow = false

  get scienceId () {
    return this.$route.params.id as string
  }

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.scienceId && this.getDetail()
  }

  pickerOptions: any = {
    // 限制时间范围最小时间在今天
    disabledDate: (time: any) => {
      return time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000
    }
  }

  getDetail () {
    this.$axios.get(this.$apis.school.selectCampusScienceByScienceId, {
      scienceId: this.scienceId
    }).then(res => {
      res.date = [res.startDate, res.endDate]
      this.info = res || {}
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.fileList.push(file)
  }

  imgRemove (index: number) {
    this.info.fileList.splice(index, 1)
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.info.startDate = this.info.date[0]
        this.info.endDate = this.info.date[1]
        const urls = this.scienceId ? this.$apis.school.updateCampusScience : this.$apis.school.insertCampusScience
        const info = this.scienceId ? {
          ...this.info,
          scienceId: this.scienceId
        } : {
          ...this.info
        }
        delete info.date
        this.$axios.post(urls, info).then(() => {
          const text = this.scienceId ? '编辑成功' : '新增成功'
          this.$message.success(text)
          this.$router.push({ name: 'cultureList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
